/* requires:
polyfill.js
*/

const findCourseForm = {
  init: function (formId) {
    const theForm = document.getElementById(formId);

    this.validateOnBlur(theForm);
    this.populateStates(theForm);

    // listening for click on the submit button
    const submitBtn = theForm.querySelector('button[type="submit"]');
    submitBtn.addEventListener(
      'click',
      function (e) {
        e.preventDefault();
        const isFormValid = findCourseForm.isFormValid(theForm);
        if (!isFormValid) {
          alert('Please select your state and choose a topic.');
          return false;
        } else {
          window.location = theForm.action;
        }
      },
      true
    );
  },

  populateStates: function (theForm) {
    const stateSelect = theForm.querySelector('[name="state"]');
    const categorySelect = theForm.querySelector('[name="category"]');

    // populate state dropdown
    let states = '<option value="" selected="selected" disabled="disabled">Select Your State</option>';
    for (let key of Object.keys(coursesMap)) {
      states = states + `<option value="${key}">${key}</option>`;
    }
    stateSelect.innerHTML = states;

    // listen for change events on the state dropdown
    stateSelect.addEventListener('change', function () {
      // console.log(this.value);
      categorySelect.disabled = false;
      findCourseForm.populateCategories(theForm, this.value);
    });
  },

  populateCategories: function (theForm, state) {
    const categorySelect = theForm.querySelector('[name="category"]');
    const categories = coursesMap[state];
    let categoryOptions = '<option value="" selected="selected" disabled="disabled">Choose a Topic</option>';

    // populate category dropdown
    for (let [key, value] of Object.entries(categories)) {
      if (value) {
        categoryOptions = categoryOptions + `<option value="${key}">${key}</option>`;
      }
    }
    categorySelect.innerHTML = categoryOptions;

    // listen for change events on the state dropdown
    categorySelect.addEventListener('change', function () {
      // console.log(this.value);
      // update the form's action with the url
      theForm.action = categories[this.value];
    });
  },

  validateOnBlur: function (theForm) {
    if (!theForm) return false;

    // realtime form fields validation using blur event
    const fields = theForm.querySelectorAll('.required');
    for (let field of fields) {
      field.addEventListener(
        'blur',
        function (e) {
          const x = field.value;
          if (!x || x.trim() == '') {
            field.classList.add('is-invalid');
          } else {
            field.classList.remove('is-invalid');
          }
        },
        true
      );
    }
  },

  isFormValid: function (theForm) {
    if (!theForm) return false;

    // form fields validation during submission
    const fields = theForm.querySelectorAll('.required');
    let isFormValid = true;
    for (let field of fields) {
      const x = field.value;
      if (!x || x.trim() == '') {
        field.classList.add('is-invalid');
        isFormValid = false;
      } else {
        field.classList.remove('is-invalid');
      }
    }
    return isFormValid;
  },
};

const coursesMap = {
  ['Alabama']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: 'https://www.tradesmance.com',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: '',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Alaska']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: 'https://www.tradesmance.com',
    ['Incorporation']: '',
    ['Insurance License']: '',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: '',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.alaskadriver.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Arizona']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.efoodhandlers.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: '',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.safemotorist.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Arkansas']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.arkansasdriver.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['California']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: 'https://www.californiadriveredcourse.com',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: 'https://www.maturedrivercourse.com',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.aypotech.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.safemotorist.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Colorado']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: 'https://www.firsttimedriver.com',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.plumberstraininginstitute.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.coloradoonlinetrafficschool.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Connecticut']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.architectstraininginstitute.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Delaware']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: 'https://www.safemotorist.com',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.aypotech.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.safemotorist.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['District of Columbia']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: '',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: '',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.vacontractortraining.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.dcdefensivedriving.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Florida']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: 'https://www.floridaboatingcourse.com',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.tradesmance.com',
    ['Driver Education']: 'https://www.firsttimedriver.com',
    ['Driving Records']: 'https://www.floridadrivingrecord.com',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: 'https://www.tradesmance.com',
    ['Incorporation']: 'https://www.floridaincorporationservice.com',
    ['Insurance License']: 'https://www.floridainsurancelicense.com',
    ['Insurance Reduction']: 'https://www.maturedrivercourse.com',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: 'https://www.floridaparentingclass.com',
    ['Personal Care Services']: 'https://www.floridaonlinecosmetology.com',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.onlineautodealered.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.floridaonlinetrafficschool.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Georgia']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: 'https://www.joshuaslaw.com',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: 'https://www.tradesmance.com',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.cosmetologytogo.com',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.georgiadriver.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Hawaii']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: '',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.architectstraininginstitute.com',
    ['Real Estate']: '',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Idaho']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: '',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Illinois']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: 'https://www.firsttimedriver.com',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: '',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Indiana']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.efoodhandlers.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.indianadriver.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Iowa']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.efoodcard.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: 'https://www.tradesmance.com',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Kansas']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: 'https://www.tradesmance.com',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Kentucky']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: 'https://www.tradesmance.com',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Louisiana']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.aypotech.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.louisianadriver.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Maine']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.aypotech.com',
    ['Real Estate']: '',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Maryland']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.vacontractortraining.com',
    ['Real Estate']: '',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Massachusetts']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.architectstraininginstitute.com',
    ['Real Estate']: '',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Michigan']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.plumberstraininginstitute.com',
    ['Real Estate']: 'https://www.realestatetraininginstitute.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.safemotorist.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Minnesota']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Mississippi']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: '',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Missouri']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.missourionlinedriverimprovement',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Montana']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Nebraska']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.architectstraininginstitute.com',
    ['Real Estate']: '',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Nevada']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: 'https://www.firsttimedriver.com',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.aypotech.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.trafficschooltogo.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['New Hampshire']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.plumberstraininginstitute.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['New Jersey']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: 'https://www.safemotorist.com',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.architectstraininginstitute.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.safemotorist.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['New Mexico']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.tradesmance.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.efoodhandlers.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: 'https://www.tradesmance.com',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: '',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['New York']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: 'https://www.safemotorist.com',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: 'https://www.safemotorist.com',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.architectstraininginstitute.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.safemotorist.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['North Carolina']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.ncinsurancelicense.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.cosmetologytogo.com',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.plumberstraininginstitute.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['North Dakota']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: '',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: '',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
    ['Electrical']: 'https://www.jadelearning.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
  },
  ['Ohio']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: 'https://www.firsttimedriver.com',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: 'https://www.tradesmance.com',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.aypotech.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Oklahoma']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.tradesmance.com',
    ['Driver Education']: 'https://www.firsttimedriver.com',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.efoodhandlers.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: 'https://www.tradesmance.com',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.plumberstraininginstitute.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.oklahomadriver.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Oregon']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.ccblicense.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.efoodcard.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Pennsylvania']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: 'https://www.firsttimedriver.com',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: 'https://www.tradesmance.com',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Rhode Island']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.tradesmance.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.aypotech.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['South Carolina']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.plumberstraininginstitute.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['South Dakota']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: '',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Tennessee']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: 'https://www.maturedrivercourse.com',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.tennesseeonlinetrafficschool.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Texas']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.tradesmance.com',
    ['Driver Education']: 'https://www.safemotorist.com',
    ['Driving Records']: 'https://www.texasdrivingschool.com',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: 'https://www.tradesmance.com',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: 'https://www.safemotorist.com',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.cosmetologytogo.com',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.plumberstraininginstitute.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.texasdrivingschool.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Utah']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.tradesmance.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Vermont']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: '',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Virginia']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.vacontractortraining.com',
    ['Driver Education']: 'https://www.firsttimedriver.com',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.efoodcard.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: 'https://www.tradesmance.com',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.vacontractortraining.com',
    ['Professional Trades']: 'https://www.vacontractortraining.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.safemotorist.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Washington']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: '',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: 'https://www.washingtondriver.com',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['West Virginia']: {
    ['Alcohol Seller/Server']: 'https://www.sellerserveronline.com',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.licensetobuild.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Wisconsin']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: 'https://www.wicontractortraining.com',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: 'https://www.notaries.com',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: 'https://www.tradesmance.com',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: 'https://www.aypotech.com',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
  ['Wyoming']: {
    ['Alcohol Seller/Server']: '',
    ['Architect']: 'https://www.architectstraininginstitute.com',
    ['Boating']: '',
    ['Cannabis']: 'https://www.cannabiseducationcenter.com',
    ['CDL Training/ELDT']: 'https://www.safemotorist.com/commercial-driving/eldt-training/',
    ['Construction Safety']: 'https://www.oshaeducationcenter.com',
    ['Contractor']: '',
    ['Driver Education']: '',
    ['Driving Records']: '',
    ['Electrical']: 'https://www.jadelearning.com',
    ['EM 385-1-1']: 'https://www.oshaeducationcenter.com',
    ['Food Handler/Manager']: 'https://www.statefoodsafety.com',
    ['General Industry Safety']: 'https://www.oshaeducationcenter.com',
    ['HAZWOPER']: 'https://www.oshaeducationcenter.com',
    ['HVAC']: '',
    ['Incorporation']: '',
    ['Insurance License']: 'https://www.einsurancetraining.com',
    ['Insurance Reduction']: '',
    ['Notary Services']: '',
    ['OSHA 10 & 30']: 'https://www.oshaeducationcenter.com',
    ['Parenting']: '',
    ['Personal Care Services']: 'https://www.abovetraining.com/bloodborne-pathogens',
    ['Pesticide Application']: 'https://www.certifiedtraininginstitute.com',
    ['Plumbing']: '',
    ['Professional Trades']: 'https://www.tradesmance.com',
    ['Real Estate']: 'https://www.ayporealestate.com',
    ['Technical Skills']: 'https://www.tpctraining.com',
    ['Traffic Violations']: '',
    ['UST Operator Training']: 'https://www.ustoperatortraining.com',
    ['Water']: '',
    ['Workforce Development']: 'https://www.metrixlearning.com',
  },
};
